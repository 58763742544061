<template>
  <!-- Maintenance mode: on -->
  <div v-if="maintenance">
    <Maintenance />
  </div>
  <!-- Maintenance mode: off -->
  <div v-else>
    <Loader />
    <div class="wrapper">
      <HeaderStyle2 />
      <div style="overflow-x: hidden;margin-top:-100px;">
        <transition
          name="router-anim"
          enter-active-class="animated  fadeInUp"
          mode="out-in"
          leave-active-class="animated fadeOut"
        >
          <router-view />
        </transition>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>
<script>
import HeaderStyle2 from '@/components/core/partials/HeaderStyle/HeaderStyle2.vue'
import LayoutFooter from '@/layouts/Components/LayoutFooter.vue'
import Loader from '@/components/core/loader/Loader'
import Maintenance from '@/views/Pages/Maintenance.vue'
export default {
  name: 'Layout4',
  components: {
    Loader,
    HeaderStyle2,
    LayoutFooter,
    Maintenance
  },
  data() {
    return {
      /** set maintenance */
      maintenance: false
    }
  },
  mounted() {
    document.body.classList = 'top-tab-horizontal'
  },
  destroyed() {
    document.body.classList = ''
  }
}
</script>
<style>
@import url('../assets/css/custom.css');
@import url('../assets/css/PriceSlider.css');
</style>
